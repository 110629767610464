import Immutable, { List, Map, Seq } from 'immutable';
import { API } from '../../../Root';
import { catchBlockErrors } from '../../../shared/utils/sentry';

// davis adds
const ADD_NEW_TRUCK = 'ADD_NEW_TRUCK';
export const addNewTruck = (newTruck) => {
    return {
        type: ADD_NEW_TRUCK,
        payload: newTruck,
    }
};
//

const setPending = (value) => ({
    type: 'setPending',
    payload: value
});

const setError = (error) => ({
    type: 'setError',
    payload: error || null
});

export const setTrucks = (data) => ({
    type: 'setTrucks',
    payload: data
});


export const setTruckId = (id) => ({
    type: 'setTruckId',
    payload: id
});

export const setTruckTypes = (truckTypes) => ({
    type: 'setTruckTypes',
    payload: truckTypes
});

export const updateCarriages = (keyPath, carriageUpdate) => ({
    type: 'updateCarriages',
    payload: {
        keyPath,
        carriageUpdate,
    },
});
const SLOT_UPDATE = 'SLOT_UPDATE';
export const updateSlot = (_carriageName, slotRank, slotColumn, slotValue) => ({
    type: SLOT_UPDATE,
    payload: {_carriageName, slotRank, slotColumn, slotValue}
});

const ADDRESS_UPDATE = 'ADDRESS_UPDATE';
export const updateAddress = (address, itemIndex) => ({
    type: ADDRESS_UPDATE,
    payload: {address, itemIndex}
});

const DELETE_TRUCK_ITEM = 'DELETE_TRUCK_ITEM';
export const deleteTruckItem = (indexForRemoval) => ({
    type: DELETE_TRUCK_ITEM,
    payload: indexForRemoval,
});

const UPDATE_TRUCK_ITEMS = 'UPDATE_TRUCK_ITEMS';
export const updateTruckItems = (truckItem) => ({
    type: UPDATE_TRUCK_ITEMS,
    payload: truckItem,
});

const SET_RAISERS = 'SET_RAISERS';
export const setRaisers = (carriageID, raiserID, updatedValue) => ({
    type: SET_RAISERS,
    payload: {
        carriageID,
        raiserID,
        updatedValue
    }
});

const IO_UPDATE = 'IO_UPDATE';
export const updateIOValue = (rowIndex, ioIndex, updateKey, newValue) => ({
    type: IO_UPDATE,
    payload: {
        rowIndex,
        ioIndex,
        updateKey,
        newValue,
    }
});
const UPDATE_RANKS = 'UPDATE_RANKS';
export const updateRanks = (ranksAmount, _carriageType) => ({
    type: UPDATE_RANKS,
    payload: {ranksAmount, _carriageType}

});

const UPDATE_COLUMNS = 'UPDATE_COLUMNS';
export const updateColumns = (columnsAmount, carriageType) => ({
    type: UPDATE_COLUMNS,
    payload: {columnsAmount, carriageType}

});

const UPDATE_CARRIAGE = 'UPDATE_CARRIAGE';
export const updateCarriage = (carriageUpdate) => ({
    type: UPDATE_CARRIAGE,
    payload: carriageUpdate

});

export const updateTruck = (data, merge = true, dirty = true) => ({
    type: 'updateTruck',
    payload: {
        data,
        merge,
        dirty
    }
});

export const updateURL = (navigate) => async function (dispatch, getState) {
    const state = getState().truckDesigner.truck;
    if (state.get('truckId')) navigate(`?id=${state.get('truckId')}`);
    else navigate('?');
}

export const saveTruck = () => async function (dispatch, getState) {
    const state = getState().truckDesigner.truck;

    if (!state.dirty) return;

    try {
        dispatch(setPending(true));
        const truck = state.trucks.find(sub => sub.get('_id') === state.truckId);
        const updatedTruck = await API.call({
            endpoint: API.endpoints['TD/updateTruck'],
            method: 'POST',
            options: {
                data: truck.toJS()
            }
        });

        if (updatedTruck.error) throw updatedTruck.error;

        dispatch(updateTruck(updatedTruck, false));
        dispatch(setError(null));
    } catch (e) {
        console.error(e);
        dispatch(setError(e));
        catchBlockErrors({ error: e, fileFunction: 'truckDesigner/truckStore/saveTruck' });
    }

    dispatch(setPending(false));
}

export const addTruck = (data) => async function (dispatch, getState) {
    const state = getState().truckDesigner.truck;

    try {
        dispatch(setPending(true));
        console.log('truck', data);
        const newTruck = await API.subcall({
            endpoint: API.endpoints['TD/createTruck'],
            method: 'POST',
            options: { data }
        });

        if (newTruck.error) throw newTruck.error;

        dispatch(setTrucks(state.trucks.push(newTruck)));
        dispatch(setError(null));
    } catch (e) {
        console.error(e);
        dispatch(setError(e));
        catchBlockErrors({ error: e, fileFunction: 'truckDesigner/truckStore/addTruck' });
    }

    dispatch(setPending(false));
}

export const copyTruck = (id) => async function (dispatch, getState) {
    const state = getState().truckDesigner.truck;

    try {
        dispatch(setPending(true));
        const copiedTruck = await API.call({
            endpoint: API.endpoints['TD/copyTruck'],
            method: 'POST',
            options: {
                params: { uid: id }
            }
        });

        if (copiedTruck.error) throw copiedTruck.error;

        dispatch(addNewTruck(copiedTruck));
    } catch (e) {
        console.error(e);
        dispatch(setError(e));
        catchBlockErrors({ error: e, fileFunction: 'truckDesigner/truckStore/copyTruck' });
    }

    dispatch(setPending(false));
}

// export const fetchTrucks = () => async function (dispatch, getState) {
//     try {
//         dispatch(setPending(true));
//         const freshTrucks = await API.subscribe({
//             endpoint: API.endpoints['TD/readTrucks'],
//             method: 'get'
//         });
//         if (freshTrucks.error) throw freshTrucks.error;

//         // dispatch(setTrucks(freshTrucks.map(sub => Map(sub))));
//         dispatch(setPending(false));
//         dispatch(setError(null));
//     } catch (e) {
//         console.error(e);
//         dispatch(setError(e));
//         catchBlockErrors({ error: e, fileFunction: 'truckDesigner/truckStore/fetchTrucks' });
//     }
// }

const INITIAL_STATE = Immutable.fromJS({
    dirty: false,
    pending: false,
    error: null,
    selectedTruck: {},
    truckBackup: null,
    trucks: [],
    truckTypes: [],
})

const TruckReducer = function (state = INITIAL_STATE, action) {
    const reduce = {
        // IO_DESCRIPTION_UPDATE({
        //     rowIndex,
        //     ioIndex,
        //     updateKey,
        //     newValue,
        // }){
        //     return state.setIn([
        //         'selectedTruck',
        //         'truckItems',
        //         rowIndex,
        //         'iocomponents',
        //         ioIndex,
        //         updateKey,
        //     ], newValue)

        // },

        IO_UPDATE({
            rowIndex,
            ioIndex,
            updateKey,
            newValue,
        }){
            return state.setIn([
                'selectedTruck',
                'truckItems',
                rowIndex,
                'iocomponents',
                ioIndex,
                updateKey,
            ], newValue)

        },
        setTruckTypes(truckTypes){
            console.log('here', truckTypes);
            return state.set('truckTypes', truckTypes);
        },

        updateCarriages({keyPath, carriageUpdate}){

            const _keyPath = ['selectedTruck', 'carriageLayout'].concat(keyPath);
            return state.setIn(_keyPath, carriageUpdate);
        },

        setTruckId(selectedTruck) {
            let restoredTrucks = null;
            // if (state.dirty && state.truckBackup) {
            //     console.log('state', state);

            //     const index = state.trucks.findIndex(sub => sub.get('_id', '') === state.truckId);
            //     console.log('index', index);
            //     restoredTrucks = state.trucks.splice(index, 1, state.truckBackup);
            // }

            return state.set('selectedTruck', selectedTruck);

            // return {
            //     ...state,
            //     dirty: false,
            //     truckId: id,
            //     truckBackup: null,
            //     trucks: restoredTrucks ?? state.trucks.toList()
            // }
        },
        UPDATE_RANKS({ranksAmount, _carriageType}){
            const RANK_CARRIAGE_UPDATE_KEY = [
                'selectedTruck', 'carriageLayout', _carriageType, 'layout'
            ];

            const currentLayout =  state.getIn(
                RANK_CARRIAGE_UPDATE_KEY, List([])
            )
            const currentColumnCount = state.get('currentColumnCount', 0);
            const defaultRankRow = List().setSize(currentColumnCount).map(() => '');

            let updatedRanksLayout = List([]);
            for(let index = 0; index < ranksAmount; index++){
                const _updatedRank = currentLayout.get(index, defaultRankRow);
                updatedRanksLayout = updatedRanksLayout.push(_updatedRank);
            }
            console.log('updatedRanksLayout', updatedRanksLayout.toJS());
            return state.setIn(RANK_CARRIAGE_UPDATE_KEY, updatedRanksLayout)

        },
        
        SET_RAISERS({carriageID, raiserID, updatedValue}){
            return state.setIn(['selectedTruck', 'carriageLayout', carriageID, raiserID], updatedValue);
        },

        SLOT_UPDATE({_carriageName, slotRank, slotColumn, slotValue}){
            console.log('_carriageName', _carriageName);

            const SLOT_CARRIAGE_UPDATE_KEY = [
                'selectedTruck', 'carriageLayout', _carriageName, 'layout', slotRank, slotColumn
            ];
            return state.setIn(SLOT_CARRIAGE_UPDATE_KEY, slotValue);
        },
        UPDATE_COLUMNS({columnsAmount, carriageType}){
            const COLUMN_CARRIAGE_UPDATE_KEY = [
                'selectedTruck', 'carriageLayout', carriageType, 'layout'
            ];

            const currentLayout =  state.getIn(
                COLUMN_CARRIAGE_UPDATE_KEY, List([])
            )
            const updatedColumnLayout = currentLayout.map((row, rowIndex) => {
                let newRow = List([]);
                for(let index = 0; index < columnsAmount; index++){
                    const value = currentLayout.getIn([rowIndex, index], '');
                    newRow = newRow.push(value);
                };
                return newRow;
            });
            console.log('updatedColumnLayout', updatedColumnLayout.toJS());
            return state
                .set('currentColumnCount', columnsAmount)
                .setIn(COLUMN_CARRIAGE_UPDATE_KEY, updatedColumnLayout);

        },

        updateTruck({ data, merge, dirty }) {
            let _selectedTruck = state.get('selectedTruck', null)
            if (_selectedTruck === null) return state;

            _selectedTruck = _selectedTruck.set(data[0], data[1]);
            return state
                .set('selectedTruck', _selectedTruck)
                .set('dirty', dirty);

            // ret


            // console.log('trucks', state.get('trucks'));
            // const index = state
            //     .get('trucks', List([]))
            //     .indexOf(_truck => _truck.get('_id') === state.get('truckId', ''));
            // const currentTruck = state.getIn(['trucks', index], Map({}));
            // // const newTruck = merge ? currentTruck.merge(data) : Map(data);
            // const newTruck = currentTruck.set(data[0], data[1]);
            // let updates = state
            //     .setIn(['trucks', index], newTruck)
            //     .set('dirty', dirty);

            // const isStateClean = !state.get('dirty');
            // const isUpdateClean = !dirty;
            // const isFirstChange = isStateClean && !isUpdateClean;

            // if (isFirstChange) updates.set('truckBackup', currentTruck);
            // if (isUpdateClean) updates.set('truckBackup', null);

            // return updates;
        },
        ADDRESS_UPDATE({address, itemIndex}) {
            console.log('address', address);
            return state.setIn(['selectedTruck', 'truckItems', itemIndex, 'address'], address);
            // const updatedTrucks = state.trucks.push(truckItems);
            // return {
            //     ...state,
            //     trucks: updatedTrucks
            // }
        },

        DELETE_TRUCK_ITEM(indexForRemoval) {
            console.log('indexForRemoval', indexForRemoval);
            const truckItemRemoved = state.getIn(['selectedTruck', 'truckItems'], List([])).delete(indexForRemoval);
            return state.setIn(['selectedTruck', 'truckItems'], truckItemRemoved);
        },
        UPDATE_TRUCK_ITEMS(truckItem) {
            const truckItemsUpdate = state.getIn(['selectedTruck', 'truckItems'], List([])).push(truckItem);
            return state.setIn(['selectedTruck', 'truckItems'], truckItemsUpdate);
        },

        ADD_NEW_TRUCK(newTruck) {
            console.log('newTruck', newTruck);
            let updatedTrucks = state.get('trucks', List([])).push(newTruck);
            return state
                .set('selectedTruck', newTruck)
                .set('trucks', updatedTrucks);
        },

        setTrucks(data) {
            return state
                .set('trucks', data)
                .set('pending', false)
                .set('error', null)
        },

        // setError(error) {
        //     return { ...state, error }
        // },

        // setPending(status) {
        //     return { ...state, pending: status }
        // }

    }[action.type];

    return reduce == null ? state : reduce(action.payload);
}

export { TruckReducer };
export default TruckReducer;
